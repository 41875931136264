// About us

#about-us {

	.container {
		.grid {
			.content {
				.text-block {
					padding: 20px;
					p:first-of-type {
						font-weight: 600; }
					h2 {}
					p {
						font-size: 18px; }
					strong {
						font-weight: 600; } }

				img {
					width: 100%;
					display: block;
					float: left; } } } }

	@include grid-media($tablet) {
		padding-bottom: 0;

		.container {
			.grid {
				.content {
					padding-bottom: 50px;

					img {
						height: 50%;
						// padding: 10% 20%
						width: calc(100%/2 - 20px);
						margin: 0 auto;
						float: none;
						padding-top: 5%;
						padding-bottom: 5%; }

					h2 {
						margin-bottom: 50px; } } } } }

	@include grid-media($laptop) {
		.container {
			.grid {
				.content {
					.text-block {
						h2 {
 } } } } } }							// margin-bottom: 50px

	@include grid-media($desktop) {
		padding-bottom: 110px;

		.container {
			.grid {
				.content {
					.text-block {
						padding: 0;
						@include grid-column(5);
						@include grid-shift(7);

						h2 {
							margin-bottom: 50px; } }
					img {
						@include grid-column(6);
						@include grid-shift(-5);

						margin-top: 108px;
						border-radius: 5px;
						padding: 0; } } } } } }
