.DEV_REFRESH-style-modal{
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  background: #212121;
  color: white;
  position: fixed;
  display: inline-block;
  bottom: 20px;
  right: 0px;
  transform: translateX(100%);
  transition: transform 0.2s;
  z-index: 9999999;
}