// Drop Down

.user-nav-profile {
	width: 50px;
	height: 50px;
	background: rgba(255,255,255,.15);
	border-radius: 50px;
	float: right;
	margin-top: -15px;
	text-align: center;
	cursor: pointer;
	z-index: 3;
	position: relative;

	span {
		@include opensans(18,600,$white,50px,uppercase); }

	.user-nav-dropdown {
		box-shadow: $box-shadow;
		border-radius: 5px;
		display: none;
		width: 250px;
		background: $white;
		border: 1px solid $grey1;
		text-align: center;
		position: relative;
		padding: 15px;
		@include abs-pos (70px, 0, auto, auto, 10);

		.user-nav-profile {}

		.profile-name {
			width: 100%;
			float: left;
			clear: both;
			@include opensans(16,400,$grey2);
			text-align: left;
			margin: 0;
			padding: 0; }

		.profile {
			margin-top: 0;
			background: $blue;
			float: left;
			text-align: center;
			@include opensans(18,600,$white,50px,uppercase); }

		.user-nav {
			display: inline-block;
			margin-top: 20px;
			width: 100%;
			text-align: left;
			&:after {
				display: none; }

			li {
				margin-bottom: 5px;

				a {
					@include opensans(12a,400,$blue,$lineheight,uppercase); }

				&.last {
					margin-top: 50px; }

				a {
					text-decoration: none;

					&:hover {
						text-decoration: underline; } } } }


		&.show-menu {
			display: block; } } }
