// Expander

.expander {
	width: 100%;
	float: left;
	clear: both;

	.expander-trigger {
		cursor: pointer;
		text-decoration: none;
		user-select: none;
		display: inline-block;
		width: 100%;

		h2 {
			display: inline-block; }

		.expander-icon {
			display: inline-block;
			background: url("/sites/all/themes/anchor__public/images/arrow-blue.svg") no-repeat;
			background-size: contain;
			width: 30px;
			height: 30px;
			transform: rotate(-90deg);
			transform-origin: 50% 20%;
			transition: .3s;
			vertical-align: bottom; } }

	.expander-hidden {
		.expander-icon {
			transform: rotate(0deg); } }

	.expander-hidden + .expander-content {
		display: none;
		z-index: 9999; }

	.expander-content {
		.expander-hidden {
			.expander-icon {
				transform: rotate(0deg); } }

		.expander-hidden + .expander-content {
			display: none;
			z-index: 9999; } }

	&.expander-hidden {
		.expander-content {} } }
