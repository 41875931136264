// Default error messages (outside the "Add a new file" div)
.messages {
	&.error {
		z-index: 999999;
		background: $white;
		border: 2px solid $pink;
		border-radius: 10px;
		padding: 10px;
		margin-bottom: 30px;
		color: $pink;
		text-align: center;
		padding: 20px;
		border: none; } }

#messages {
	.messages {
		&.status {
			background: $white url("/sites/all/themes/anchor__logged_in_user/images/message-success.svg") top left / 42px 42px no-repeat;
			border: none;
			@include opensans(16,300,$grey2);
			margin: 0;
			padding: 60px 0 0 0;

			em {
				font-style: normal;
				@include opensans(16,400,$grey2); } } } }


.modal {
	#messages {
		.messages {
			&.error {
				text-align: left;
				background: $white url("/sites/all/themes/anchor__logged_in_user/images/message-error.svg") top left / 42px 42px no-repeat !important;
				border: none !important;
				@include opensans(16,300,$grey2);
				margin: 0;
				padding: 60px 0 0 0;

				em {
					font-style: normal;
					@include opensans(16,400,$grey2); }
				ul {
					text-align: left; } } } } }
