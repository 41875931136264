// Config

$neat-grid: (
	columns: 4,
	gutter: 8px,
	media: 0,
);

$mobile: (
	columns: 4,
	gutter: 8px,
	media: 0,
);

$tablet: (
	columns: 8,
	gutter: 16px,
	media: 480px,
);

$laptop: (
	columns: 12,
	gutter: 32px,
	media: 769px,
);

$desktop: (
	columns: 12,
	gutter: 32px,
	media: 1025px,
);
