// Buttons
// All the different buttons
.btn, #edit-submit {
	text-transform: uppercase;
	padding: 20px;
	border-radius: 4px;
	text-align: center;
	display: block;
	text-decoration: none;
	-webkit-appearance: none;

	&:hover {
		background: $blue;
		text-decoration: none;
		cursor: pointer; } }

.login-button {
	.btn {
		margin: 20px;
		background: $blue;
		color: $white; } }

.form-actions {
	#edit-submit {
		margin: 20px 0;
		background: $blue;
		color: $white;
		width: 100%; } }

.hide-tab {
	display: inline-block;
	width: 100%;

	.btn {
		margin: 5px 15px;
		background: $white;
		color: $blue;
		border: 2px solid $blue;
		font-weight: 600; } }

@include grid-media($tablet);
@include grid-media($desktop) {
	.hide-tab {
		display: none; } }

// Last logout button in the drop down menu
.user-nav-profile .user-nav-dropdown .user-nav .last a {
	display: block;
	background: $blue;
	color: $white;
	padding: 10px 30px;
	font-size: 14px;
	border-radius: 4px;
	margin: 5px 0;
	width: 100%;
	text-transform: uppercase;
	border: 1px solid $blue;
	transition: .2;
	text-align: center; }

.mobile-user-nav {
	.links {
		li {
			a {
				text-transform: uppercase;
				padding: 20px;
				border-radius: 4px;
				text-align: center;
				display: block;
				text-decoration: none;
				margin: 20px;
				background: $blue;

				&:last-child {
					margin-bottom: 0; }
				color: $white; } } } }
