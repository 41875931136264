* {
	box-sizing: border-box; }

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	font-family: $open-sans;
	color: $text;

	section {
		h2, .h2 {
			text-align: center; } }

	@include grid-media($mobile) {
		.mobile-user-nav {
			display: block; } }

	@include grid-media($tablet) {
		section {
			width: 100%;
			padding: 50px 0; } }

	@include grid-media($laptop) {
		section {
			width: 100%;
			padding: 100px 0; }

		&.not-front {
			section {
				padding: 0 60px 60px; } }

		.mobile-user-nav {
			.links {
				li {
					width: calc((100% / 3) - 3px);
					display: inline-block;

					a {
						transition: $transition;
						padding: 20px 15px;

						&:hover {
							background: $blue-hover; } } } } } }

	@include grid-media($desktop) {
		section {
			width: 100%;
			padding: 110px 0; }

		.mobile-user-nav {
			display: none; } } }

ul {
	padding: 0;
	margin: 0;
	list-style-type: none; }

h1 {
	margin: 0;
	color: $blue;
	font-weight: 100; }

h2, .h2 {
	margin: 0;
	color: $blue;
	font-size: 36px;
	font-weight: 100; }

p {
	font-weight: 100;
	color: $text; }

a {
	color: $blue; }

// Remove iOS styling on input fields
input:not([type="radio"]):not([type="checkbox"]) {
	-webkit-appearance: none;
	border-radius: 0; }

.not-front {
	#page {
		// Setup a space for the footer and header
		height: 100%;
		min-height: calc(100vh - (107px + 83px));

		.container {
			padding: 15px; } }

	@include grid-media($desktop) {
		#page {
			.container {
				padding: 0; } } } }

.container {
	max-width: $max-container-width;
	margin: 0 auto;

	@include grid-media($tablet);
	.grid {
		@include grid-container; }

	@include grid-media($desktop) {
		.grid {
 } } }			// @include grid-collapse

.hide-mob {
	display: none;

	@include grid-media($desktop) {
		display: block; } }

.hide-tab {
	display: inline-block;

	@include grid-media($laptop) {
		display: none !important; } }

.pull-left {
	float: left; }

.pull-right {
	float: left;

	@include grid-media($tablet) {
		float: right !important; } }
