// Mixins

// FONT VARIABLES


@mixin opensans($size: $font-size-nopx, $weight: 300, $colour: $text, $lh: $lineheight, $transform: none) {
  font-family: $open-sans;
  @if $size {
    font-size: $size + px;
    font-size: (0.0625 * $size) + rem; }
  @if $weight {
    font-weight: $weight; }
  @if $colour {
    color: $colour; }
  @if $lh {
    line-height: $lh; }
  @if $transform {
    text-transform: $transform; } }

@mixin opencond($size: $font-size-nopx, $weight: 300, $colour: $text, $lh: $lineheight, $transform: none) {
  font-family: $open-cond;
  @if $size {
    font-size: $size + px;
    font-size: (0.0625 * $size) + rem; }
  @if $weight {
    font-weight: $weight; }
  @if $colour {
    color: $colour; }
  @if $lh {
    line-height: $lh; }
  @if $transform {
    text-transform: $transform; } }

@mixin animate($animate, $ease) {
  transition: all $ease $animate + ms; }

@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto, $z-index: false) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
  position: absolute; }

@mixin fix-pos ($top: auto, $right: auto, $bottom: auto, $left: auto, $z-index: false) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
  position: fixed; }

@mixin rel-pos ($top: auto, $right: auto, $bottom: auto, $left: auto, $z-index: false) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
  position: relative; }

@mixin remove-child-margins() {
	& > * {
		&:first-child {
			margin-top: 0; }
		&:last-child {
			margin-bottom: 0; } } }

@mixin headings() {
	h1, h2, h3, h4, h5 {
		@content; } }

@mixin modal-props() {
	border-radius: $border-radius;
	border: solid 1px $blue;
	padding: 24px 24px 20px 24px;
	color: $blue-alt;
	background: white;
	box-shadow: $box-shadow;
	position: relative; }

.ed-dump {
  display: block;
  overflow-x: auto;
  tab-size: 2;
  word-spacing: 2px; }
