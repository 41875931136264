// Privacy & terms

.node-type-page {
	#page {
		text-align: center;
		margin-top: 50px;

		.container {
			max-width: 900px;
			text-align: left;

			h1 {
				@include opensans(36,300,$blue); }

			h3 {
				@include opensans(24,300,$blue,1.3em,uppercase);
				width: 100%;
				border-top: 1px solid $grey1;
				padding-top: 20px;
				margin-top: 50px;
				float: left;
				clear: both; }

			ul {
				width: 100%;
				float: left;
				clear: both;
				margin: 10px 0;

				li {
					@include opensans(16,300,$text);
					margin-bottom: 1em;
					list-style: disc;
					margin-left: 30px;

					strong {
						font-weight: 600; } } }

			p {
				@include opensans(16,300,$text);
				margin-bottom: 1em;

				strong {
					font-weight: 600; } } } } }

