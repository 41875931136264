html, :root {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 100%;
  line-height: $base-line-height;
}

hr {
  // border-bottom: $base-border;
  // border-left: 0;
  // border-right: 0;
  // border-top: 0;
  // margin: $base-spacing 0;
}

@mixin font-weight($size) {
  $font-sizes: (
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700
  );

  @if map-has-key($map: $font-sizes, $key: $size) {
    font-weight: map-get($map: $font-sizes, $key: $size);
  }
}

@mixin font-size($size, $lineHeight: null){
  font-size: rem($size);

  @if ($lineHeight) {
    line-height: $lineHeight / $size;
  }
}

@mixin uppercase(){
  text-transform: uppercase;
}

@mixin title-base(){
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}


@mixin info-light(){
  @include font-size(16px);
  @include font-weight(light);
  color: $blue-alt;
}

@mixin info-bold(){
  @include font-size(16px);
  @include font-weight(regular);
  color: $blue-alt;
}

@mixin detail-item(){
  @include font-size(12px, 16px);
  @include font-weight(regular);
}

@mixin label(){
  @include font-size(12px, 16px);
  @include font-weight(light);
  @include uppercase();
}

@mixin title-sidebar(){
  @include font-size(36px);
  @include font-weight(light);
  color: $blue;
}

@mixin border-bottom($color: $grey1){
  border-bottom: solid 1px $color;
}

@mixin border-top($color: $grey1){
  border-top: solid 1px $color;
}