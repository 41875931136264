// Forms
.page-user,
.page-user-login,
.page-user-password {
	#page {
		.container {
			padding: 20px;

			h1 {
				color: $blue;
				margin-bottom: 20px;
				font-weight: 100; }

			label {
				color: $grey2;
				font-size: 12px;

				.form-required {
					display: none; }

				input:focus {
					outline: none; } }

			input {
				width: 100%;
				padding: 12px 0;
				font-size: 18px;

				&#edit-name {
					color: $grey2; }

				.description {
					display: none; } }

			form {

				.form-item {
					position: relative;
					margin-top: 1.5em;

					input {
						border: none;
						border-bottom: 1px solid $grey1;
						@include rel-pos(auto,auto,auto,auto,2);
						@include opensans(16,300,$text);
						background: transparent;

						+ .description {
							@include opensans(16,300,$grey2);
							@include abs-pos(15px,auto,auto,0,1);
							@include animate(100,linear); }

						&:focus,
						&.filled {
							outline: none;

							+ .description {
								@include opensans(10,400,$grey2);
								@include abs-pos(-10px,auto,auto,0,1); } } } }

				input[type='submit'] {
					@include opensans(14,500,$white,1,uppercase);
					cursor: pointer; }

				div {
					a {
						text-decoration: none;
						color: $blue;
						font-size: 12px; } } } } }

	@include grid-media($tablet);
	@include grid-media($desktop) {
		#page {
			.container {
				width: 100%;
				max-width: 550px;
				margin: 100px auto 0;
				border: 1px solid $grey1;
				border-radius: 5px;
				padding: 60px 90px;
				box-shadow: -1px 1px 10px 0px rgba(0,0,0,.1); } } } }

