.not-front {
	footer {
		// position: absolute
		// bottom: 0
		// left: 0
		position: relative; } }

footer {
	float: left;
	clear: both;
	width: 100%;
	background: $grey1;
	padding: 25px 15px;

	.grid {
		@include grid-push(0);

		#footer-logo {
			margin: 0;
			float: left;
			display: block;
			width: 30px;
			height: 24px;
			margin-right: 20px;
			margin-top: 10px;
			overflow: hidden;

			svg {
				width: 125px;
				height: 100%;

				#Anchor-Logo-Master {
					fill: $blue-alt; } } }

		// #footer-logo
		// 	margin-top: -5px
		// 	height: 55px
		// 	width: 55px
		// 	float: left

		// 	#anchor_logo
		// 		height: 100%
		// 		width: max-content
		// 		margin-left: -20px

		// 	.st0
		// 		fill: $grey2
		// 		display: none

		// 	.st1
		// 		fill: $grey2

		#footer-menu {
			.links {
				display: inline-flex;

				li {
					&:nth-of-type(2) {
						@include grid-shift(1); }

					a {
						font-size: 10px;
						text-transform: uppercase;
						color: $grey2; } } } }

		.copyright {
			color: $grey2;
			font-size: 10px; } }

	@include grid-media($tablet) {
		.grid {
			#footer-logo {
				width: 125px;
				height: 24px;
				margin-top: 5px;

				svg {
					width: 100%;
					height: 100%; }

				.st0 {}
				#anchor_logo {
					margin-left: -5px; } } } }

	@include grid-media($desktop) {
		padding: 30px 25px;

		.grid {
			text-transform: uppercase;
			font-size: 12px;

			#footer-logo {
				width: 125px;
				height: 24px;
				display: block;
				margin-right: 0;
				margin-top: -10px;

				.st0 {
					fill: $grey2;
					display: block; }

				.st1 {
					fill: $grey2; } }

			.copyright {
				font-size: 12px;
				float: right; }

			#footer-menu {
				font-size: 12px;
				float: right;

				.links {
					display: inline-flex;

					li {
						a {
							color: $grey2;
							font-size: 12px; }

						&:nth-of-type(1) {
							margin: 0 50px; }

						&:nth-of-type(2) {
							position: inherit;
							margin-right: 20px; } } } } } } }
