// Contact us

#contact-us {
	float: left;
	clear: both;
	width: 100%;
	border-top: 1px solid $grey1;
	padding-bottom: 0;

	.container {
		.grid {
			.content {
				.text-block {
					padding: 20px;
					p {
						a {
							text-decoration: none;
							color: $text; } }

					strong {
						font-weight: 600; } } } } }

	#anchor-map {
		width: 100%;
		height: 250px; }

	@include grid-media($tablet) {
		.container {
			.grid {
				.content {
					.text-block {
						padding-top: 0; } } } } }

	// Add - .floating-text-block class to the floating only element in the DOM
	@include grid-media($laptop) {
		.container {
			.grid {
				.content {
					h2 {
						padding: 0 0 20px; } } } } }

	@include grid-media($desktop) {
		padding-top: 0;

		.container {
			.grid {
				.content {
					@include grid-column(4);
					@include grid-shift(1);
					.text-block {
						display: block;
						z-index: 99;
						position: absolute;
						top: 150px;
						background: $white;
						padding: 0 30px 15px 15px;
						border: 2px solid $grey1;
						border-radius: 4px;
						box-shadow: -5px 5px 10px 5px rgba(255,255,255,.2);

						h2 {
							padding: 20px 0;
							text-align: left; }

						strong {
							font-weight: 600; }

						p {
							line-height: 1.6;
							font-size: 18px; }

						p:nth-of-type(1) {
							border-bottom: 1px solid $grey1;
							padding-bottom: 20px;
							margin: 0; }

						p:nth-of-type(2) {
							margin: 0;
							padding: 20px 0; } } } } }

		#anchor-map {
			height: 750px; } } }
