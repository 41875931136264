// Everything in here will need to be recoped into the right sections

// $grey1: #EEEEEE
// Change to?
// $grey1: #DDDDDD
// The home page mockup has #DDDDDD as $grey1. Should we change the #EEEEEE to #DDDDDD? There are currently grey1, grey2, grey3 variables.


// Front page tweaks
.front {
	.banner {
		padding-top: 0 !important; }

	@include grid-media($desktop) {

		#top {
			.container {
				.grid {
					#logo {}
					nav {
						ul {
							li {
								a {
									font-size: 14px; } } } } } } }
		.banner {
			// padding-top: 40px
			// remove the padding, half width desktop makes some padding on top when on a lower resolution
			padding-top: 0 !important; }

		#about-us {
			.container {
				.grid {
					.content {
						.text-block {
							h2 {}
							// The About us title should be LEFT ALIGNED, not centred.
 } } } } }							//ext-align: left

		#what-we-do {
			// The thin grey lines that separate each section in the landing page (two lines in total) look lighter/thinner than the prototype.
			border-top: 1px solid $grey1;
			.container {
				.grid {
					.content {
						.slider-what-we-do-container {
							h2 {
								margin-bottom: 30px; }

							.slider-what-we-do {
								margin: 0;
								.slick-list {
										.slick-track {
											.views-row {
												// The spacing between the four items in the 'What we can do for you' section looks too tight
												margin-top: 20px;
												width: calc(50% - 10px)!important;
												padding: 0 10px;

												// typesize also looks a bit different
												strong {
													// TODO: Mockups from sketch are 18px and semi-bold - same as below?
													font-size: 18px;
													font-weight: 600; }

												p {
													font-size: 18px;
													font-weight: 200; } } } } } } } } } }

		#team {
			// The thin grey lines that separate each section in the landing page (two lines in total) look lighter/thinner than the prototype.
			border-top: 1px solid $grey1; }

		#contact-us {
			.container {
				.grid {
					.content {
						.text-block {
							// Grey strokeweight is slightly different.
							border-bottom: 1px solid $grey1;
							// The rounded corners should also look the same as the prototype (Radius in Sketch is 5, same as the corner radius on the two square images)
							border-radius: 5px;
							// A subtle drop shadow shwuld be added as well.
							box-shadow: -2px 2px 6px 5px rgba(255,255,255,.2);
 } } } } } }							// This is different to the other shadow on the sidebar

	footer {
		padding: 50px 25px 45px; } }


// #login page
// http://anchorlogistics.com.au/user/login
.page-user-login {
	#page {
		.container {
			form {
				.form-item {
					input {
						// TODO rescope
						color: $text !important;
						// I've just reused a close color
						// $grey1: #EEEEEE
						// $grey2: #9B9B9B
						// $grey3: #FBFBFA
						// $text: #4A4A4A

						&:focus {
							// The fix states the focus underline should be #5E5E5E
							border-bottom: 1px solid $text; } } } } } } }






//Anchor-booking page
// Autocomplete dropdown section
#autocomplete {
	background: $white;
	padding: 5px;
	width: 100% !important; }
