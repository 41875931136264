// Team

#team {
	width: 100%;
	float: left;
	clear: both;
	border-top: 1px solid $grey1;

	.container {
		.grid {
			.expander {
				.h2 {
					padding: 20px 0; }

				.expander-content {
					.views-row {
						.team-member {
							padding: 20px 20px 0;

							p {
								margin: 0;
								font-weight: 400;

								a {
									text-decoration: none;
									color: $blue;
									font-weight: 400; } }
							.name {}
							.title {
								font-size: 18px; }

							.qualification {
								color: $grey2; }

							p:last-of-type {
								border-bottom: 1px solid $grey1;
								padding-bottom: 15px; } } } } } } }

	@include grid-media($tablet) {
		.container {
			.grid {
				.expander {
					.h2 {
						padding: 0; } } } } }

	@include grid-media($laptop) {
		.container {
			.grid {
				.expander {
					margin-bottom: 130px;

					.h2 {
						@include grid-column(12);
						margin-bottom: 120px;
						text-align: left;
						padding: 0; }

					.expander-icon {
						display: none; }

					.expander-trigger {
						pointer-events: none;
						cursor: default;

						&:before {
							display: none; } }

					.expander-hidden {
						+ .expander-content {
							display: block; } }

					.views-row {
						@include grid-column(4);
						float: left;
						justify-content: space-between;
						flex-direction: row;
						height: 200px;
						display: inline-flex;
						display: flex;

						.team-member {
							padding: 0; }

						&.views-row-odd {
							clear:both {} } }

					.expander-content {
						.views-row {
							.team-member {
								padding: 0;

								.name {
									font-size: 18px;
									font-weight: 600; }

								.title {}
								.qualification {
									margin-bottom: 20px; }

								p {
									margin: 0;

									&:last-of-type {
										border-bottom: none;
										padding: 0; } }

								&:last-of-type {
									border-bottom: 0; }

								a {
									transition: $transition;

									&:hover {
										text-decoration: underline; } } } } } }

				.hide-mob {
					@include grid-shift(2);
					@include grid-column(8);
					border-radius: 5px;
					display: block; } } } } }
