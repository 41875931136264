// Header
.front {
	.user-nav-profile {
		background: $blue;
		margin-top: 0;
		transition: $transition;
		border: 1px solid $blue;

		&:hover {
			// background: rgba(255,255,255,.3)
			background: $blue;
			border: 1px solid $blue-hover;
			text-decoration: none;
			cursor: pointer; }

		&:hover {
			background: #6594CC; }

		&.active {
			border: 1px solid $blue-hover;
			background-color: $blue-hover; } }


	@include grid-media($desktop) {
		#top {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 100;
			border-bottom: 1px solid $grey1; }

		.banner {
			padding-top: 50px; } } }

header {
	background: $white;
	padding: 30px 15px;
	text-align: center;
	text-transform: uppercase;

	#logo {
		width: 220px;
		margin: 0;
		padding: 0;
		float: none;
		display: inline-block;

		#anchor_logo {
			margin-top: 2px;
			.st1 {
				fill: $blue;

				nav {
					.links {
						li {
							display: inline-flex;

							a {
								color: $white; } } } } } } }

	body.not-logged-in & {
		text-align: center;

		.grid {
			#logo {
				#anchor_logo {
					.st1 {
						fill: $blue; } } } } }

	body.not-front & {
		background: $blue;

		.grid {
			#logo {
				#anchor_logo {
					.st1 {
						fill: $white; } }

				svg {
					#Anchor-Logo-Master {
						fill: $white; } } }
			nav {
				.links {
					li {
						a {
							color: $white; } } } } } }

	.user-nav-container {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		border: 1px solid $grey1;
		background: white;

		ul {
			margin: 60px 0 0 -120px;
			background: $white;
			padding: 20px;
			border-radius: 10px;
			border: 1px solid $grey1;

			li {
				a {
					text-decoration: None;
					color: $blue; } }

			&.visible {
				display: block; } } }

	@include grid-media($tablet) {
		padding: 30px;

		#main-menu {
			display: block; }

		.container {
			.grid {
				width: 100%;
				padding: 0;
				margin: 0;
				margin-left: 0;
				margin-right: 0;

				#logo {
					width: 280px; } } } }


	@include grid-media($desktop) {
		.container {
			.grid {
				#logo {
					width: 280px; } } } }

	@include grid-media($desktop) {
		text-align: left;

		.container {
			text-align: left;

			.grid {
				display: inline-flex;

				#logo {
					height: 42px;
					margin: 0;
					padding: 0;
					float: none;
					display: inline-block;
					width: 340px;
					margin-right: 20px;

					#anchor_logo {
						display: block;

						.st1 {
							fill: $blue; } } }

				nav {
					justify-content: flex-end;
					display: inline-flex !important;
					flex: 1;

					.links {
						display: inline-flex;
						justify-content: space-around;
						margin-top: 10px;

						li {
							margin: 0 30px 0 0;
							flex-direction: row;

							a {
								color: $blue;
								text-decoration: none;
								margin-top: 0; } } }

					.btn {
						display: inline-flex;
						color: $white;
						background: $blue;
						padding: 10px 60px;
						border-radius: 4px;
						margin: 0 0 0 30px;

						&:hover {
							background-color: $blue-hover;
							cursor: pointer; } } } } } } }
