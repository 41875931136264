// Navigation

header {

	#main-menu {
		display: none; }

	.login-button {
		display: none; }

	.user-nav {
		display: none; }

	@include grid-media($tablet);
	@include grid-media($desktop) {
		#main-menu {
			display: block; }

		.login-button {
			display: block; }

		.user-nav {
			display: block; } } }
