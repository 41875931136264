// Homepage banner

.banner {
	width: 100%;
	clear: both;
	margin: 0;
	padding: 0;
	text-align: center;
	position: relative;
	overflow: hidden;
	color: $white;

	img {
		width: auto;
		max-height: 200px;
		float: none;
		clear: both;
		display: block;
		margin: 0 0 0 50%;
		transform: translateX(-50%); }

	h1 {
		@include abs-pos(0,auto,auto,0,1);
		width: 100%;
		text-align: center;
		font-size: 20px;
		font-weight: 100;
		color: $white;
		padding: 10px 20px; }

	p {
		display: none;
		color: $white;
		font-size: 18px; }

	@include grid-media($tablet) {
		img {
			max-height: 350px; }

		h1 {
			font-size: 22px;
			@include abs-pos(auto,auto,0,0,1);
			margin-bottom: 20px;
			padding: 0 25%; } }

	@include grid-media($laptop) {
		img {
			width: 100%; }
		h1 {
			bottom: 2.2em; }
		p {
			bottom: 1em; } }

	@include grid-media($desktop) {
		img {
			width: 100%;
			max-height: none; }

		h1 {
			font-size: 36px;
			margin-bottom: 0;
			@include abs-pos(auto,auto,4.2em,0,1); }

		p {
			display: block;
			@include abs-pos(auto,auto,5em,0,1);
			width: 100%;
			text-align: center; } } }
