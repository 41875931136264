//What we do

#what-we-do {
	border-top: 1px solid $grey1;
	.container {
		.grid {
			.content {
				.slider-what-we-do-container {
					padding: 0 20px;

					h2 {
						padding: 20px 0; }

					.slider-what-we-do {
						text-align: center;
						margin-top: 20px;

						.views-row {
							img {
								display: initial; }
							p {
								padding: 0 20px;
								margin: 0;
								color: $text;
								font-size: 16px; }

							strong {
								margin-top: 10px;
								font-size: 18px;
								font-weight: 600;
								display: block; } }

						.slick-dots {
							margin-top: 40px;
							padding-bottom: 20px;

							li {
								display: inline-block;
								padding-bottom: 20px;

								button {
									color: rgba(0,0,0,0);
									background: $grey1;
									border: none;
									border-radius: 50%;
									margin: 0 10px;
									line-height: 2.6;
									font-size: 5px; }

								&.slick-active {
									button {
										background: $blue; } } } } } } } } }

	@include grid-media($tablet) {
		.container {
			.grid {
				.content {
					.slider-what-we-do-container {
						padding: 0;

						h2 {
							margin: 0;
							padding: 0; }

						.slider-what-we-do {
							margin: 40px 0 0;

							.views-row {
								// width: 50%
								// float: left
								// margin-bottom: 40px

								&:nth-of-type(3) {
 }									// clear: both

								p {
									padding: 0 30px; } }

							.slick-list {
								.slick-track {
									.views-row {
										width: auto;
										height: auto; } } }

							img {
								width: auto;
								float: none; }

							.slick-dots {
								display: none; } } }

					img {
						width: calc(100% / 2 - 20px);
						display: block;
						margin: 0 auto;
						padding-top: 10%;
						padding-bottom: 5%; } } } } }

	@include grid-media($laptop) {
				.container {
					.grid {
						.content {
							.slider-what-we-do-container {
								padding: 0;

								.slider-what-we-do {
									.views-row {
										width: 50%;
										float: left;
										margin-bottom: 40px;

										&:nth-of-type(3) {
											clear: both; }

										p {
											padding: 0 30px; } }

									.slick-list {
										.slick-track {
											.views-row {
												width: 50% !important; } } } } } } }
					img {
						padding-top: 0; } } }


	@include grid-media($desktop) {
		.container {
			.grid {
				.content {
					img {
						border-radius: 5px; }

					.slider-what-we-do-container {
						@include grid-column(6);

						clear: both;
						text-align: center;

						h2 {
							margin-bottom: 80px;
							padding-top: 0; }

						.slider-what-we-do {
							@include grid-push(1);

							.slick-list {
								.views-row {}

								.slick-list {
									.slick-track {
										.views-row {
											width: 100% !important; } } } }


							.slick-dots {
								display: none; } } } } } } } }
