// Modal

.modal,
.message-modal {
	$base-border-color: #dcdcdc !default;
	$base-border-radius: 3px !default;
	$base-background-color: #fff !default;
	$base-font-size: 14px !default;
	$base-line-height: 1.5em !default;
	$action-color: #477dca !default;
	$dark-gray: #333 !default;
	$light-gray: #ddd !default;
	$medium-screen: 40em !default;
	$large-screen: 53.75em !default;
	$base-font-color: $dark-gray !default;
	$modal-padding: 3em;
	$modal-background: $base-background-color;
	$modal-close-color: $light-gray;
	$modal-image-height: 135px;
	$modal-image-width: $modal-image-height;
	$modal-trigger-image-width: 300px;

	label {
		cursor: pointer;
		margin-bottom: 0;

		img {
			display: block; } }

	.modal-state {
		display: none; }

	.modal-trigger {
		padding: 0.8em 1em; }

	.modal-fade-screen { // overlay
		@include position(fixed, 0);
		background-color: rgba(#000, 0.85);
		opacity: 0;
		padding-top: 0.6em;
		text-align: left;
		transition: opacity 0.25s ease;
		visibility: hidden;
		z-index: 99999999999;

		@include grid-media($desktop) {
			padding-top: 10em; }

		.modal-bg {
			@include position(absolute, 0);
			cursor: pointer; } }

	.modal-close {
		@include position(absolute, ($modal-padding /2) ($modal-padding /2) null null);
		@include size(1.5em);
		background: $modal-background;
		cursor: pointer;

		&::after,
		&::before {
			@include position(absolute, 3px 3px 0 50%);
			@include size(0.15em, 1.5em);
			background: $modal-close-color;
			content: "";
			display: block;
			margin: -3px 0 0 -1px;
			transform: rotate(45deg); }

		&:hover::after,
		&:hover::before {
			background: darken($modal-close-color, 10%); }

		&::before {
			transform: rotate(-45deg); } }

	.modal-inner {
		background: $modal-background;
		border-radius: $base-border-radius;
		margin: auto;
		margin-top: 0;
		max-height: 95%;
		overflow: auto;
		padding: $modal-padding / 2;
		position: relative;
		transition: opacity 0.25s ease;
		width: 95%;

		.row {
			background: $white;
			border-top: 1px solid $grey1;
			border-radius: 0;

			&:first-child {
				margin-top: 15px; }

			&:last-child {
				border-bottom: 1px solid $grey1; }

			.date {
				font-weight: 600;
				font-size: 14px;
				margin: 10px 0; }

			.log {
				font-weight: 400;
				font-size: 14px;
				margin-bottom: 15px; }

			.author {
				font-weight: 600;
				font-size: 14px;
				margin-bottom: 15px; }

			.link {} }

		.history-icon {
			float: left;
			margin-right: 8px; }

		.h1 {
			font-size: 30px;
			font-weight: 300; }


		@include grid-media($tablet) {
			max-height: 70%;
			padding: $modal-padding;
			width: 60%; }

		@include grid-media($desktop) {
			width: 50%;

			.row {
				background: $white;
				border-radius: 0;
				padding: 10px 0 5px;
				display: inline-flex;

				.date {
					flex: 2;
					margin: 0;
					font-weight: 600; }

				.log {
					flex: 2;
					margin: 0;
					margin: 0 5px; }

				.author {
					flex: 1;
					margin: 0;
					text-align: right;
					font-weight: 600; }

				.link {
					flex: 1;
					margin: 0;
					text-align: right; }

				div {
					font-size: 14px; } }

			.history-icon {
				height: 30px;
				width: 30px;
				margin-top: -10px;
				margin-right: 15px;
				display: inline-flex;
				float: left;

				svg {
					height: 40px;
					width: 40px; } }

			.h1 {
				margin: 0;
				color: $blue;
				font-weight: 100;
				font-size: 30px;
				margin-bottom: 15px;
				display: inline-flex; } }

		// h1
		// 	color: $base-font-color
		// 	text-align: left
		// 	text-transform: capitalize

		// p
		// 	color: $base-font-color
		// 	line-height: $base-line-height

		.modal-intro {
			margin-bottom: 1em; }

		.modal-content {
			color: $base-font-color;

			@include grid-media($tablet);

			.btn {
				width: auto;
				float: left;
				padding: 10px 25px; } }

		a.cta {
			color: $base-background-color;
			display: inline-block;
			margin-right: 0.5em;
			margin-top: 1em;

			&:last-child {
				padding: 0 2em; } } }

	.modal-open & {
		.modal-fade-screen {
			opacity: 1;
			visibility: visible; } }

	.modal-open & {
		.modal-fade-screen .modal-inner {
			top: 0.5em; } }

	.input-wrapper {
		width: 100%;
		float: left;
		clear: both;
		position: relative;

		input {
			@include opensans(16,300,$text);
			@include rel-pos(auto,auto,auto,auto,1); }

		label {
			@include opensans(16,300,$grey2);
			@include abs-pos(15px,auto,auto,0,0);
			@include animate(100,linear);
			opacity: 0;

			span {
				@include opensans(12,300,$pink); } }

		input:focus,
		input:not(:placeholder-shown) {
			+ label {
				@include opensans(12,300,$grey2);
				@include abs-pos(-10px,auto,auto,0,0);
				opacity: 1; } }

		input:focus {
			border-bottom: 1px solid $blue;

			&::-webkit-input-placeholder {
				color: transparent; }

			&::-moz-placeholder {
				color: transparent; }

			&:-ms-input-placeholder {
				color: transparent; }

			&:-moz-placeholder {
				color: transparent; } } }

	.select-wrapper {
		width: 100%;
		float: left;
		clear: both;
		position: relative;

		label {
			@include opensans(12,300,$grey2);
			margin-bottom: 5px;

			span {
				@include opensans(12,300,$pink); } }

		select {
			width: 100%;
			border-bottom: 1px solid $grey1;
			@include opensans(16,300,$grey2); } }

	.textarea-wrapper {
		width: 100%;
		float: left;
		clear: both;
		position: relative;

		label {
			@include opensans(12,300,$grey2);
			margin-bottom: 5px;

			span {
				@include opensans(12,300,$pink); } }

		textarea {
			width: 100%;
			height: 200px;
			border-radius: 0;
			border: none;
			border-bottom: 1px solid $grey1;

			&:focus {
				border-bottom: 1px solid $blue; } } } }


.modal-open {
	overflow: hidden; }

.modal {
	.message-modal-open & {
		#messageModal {
			&.modal-fade-screen {
				opacity: 1;
				visibility: visible; } } }

	.message-modal-open & {
		#messageModal {
			&.modal-fade-screen {
				.modal-inner {
					top: 0.5em; } } } }

	.modal-open & {
		#messageModal {
			&.modal-fade-screen {
				opacity: 0;
				visibility: hidden; } } }

	.modal-open & {
		#messageModal {
			&.modal-fade-screen {
				.modal-inner {
					top: 0; } } } } }

