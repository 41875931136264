// COLOUR VARIABLES FOR ANCHOR PLATFORM

$black: #000000;
$white: #ffffff;

$blue-light: #009FEF;
$blue: #0065DB;
$blue-dark: #263141;
$blue-hover: #0076FF;
$blue-alt: #455C7A;

$outstanding: #EBAC78;
$paid: $blue;

$grey1: #EEEEEE;
$grey2: #9B9B9B;
$grey3: #FBFBFA;
$stroke: #DBD5CD;

$green: #7ED321;
$orange: #F5A623;
$pink: #FD6275;
$yellow: #F8E71C;

$placeholder: #C4BCB0;

$red: #D0021B;
$red-dark: #AA0116;

// TEXT
$text: #4A4A4A;
$lineheight: 1.3em;
$font-size-nopx: 16;

$opacity1: .15;
$opacity2: .55;

$transition: 0.3s;
$padding: 10px;
$border-radius: 8px;

$open-sans: 'Open Sans', sans-serif;
$open-cond: 'Open Sans Condensed', sans-serif;

$base-font-color: $text !default;
$base-font-family: $open-sans !default;
$base-line-height: $lineheight !default;

// BOX SHADOWS
$box-shadow: 0px 16px 24px 0 rgba(219, 213, 205, 0.5);
$box-shadow--small: 0px 4px 8px 0 rgba(219, 213, 205, 0.5);

// Container max width
$max-container-width: 1200px;